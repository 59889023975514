<template>
  <div class="cal-index">
    <div class="cal-control">
      <el-button-group>
        <el-button type="primary" @click="handlePrev" icon="el-icon-arrow-left"></el-button>
        <el-button type="primary" @click="handleNext" icon="el-icon-arrow-right"></el-button>
      </el-button-group>
      <div class="text">
        {{ formattedMonth }}
      </div>
    </div>
    <div class="cal-header">
      <div class="weekday">星期一</div>
      <div class="weekday">星期二</div>
      <div class="weekday">星期三</div>
      <div class="weekday">星期四</div>
      <div class="weekday">星期五</div>
      <div class="weekday">星期六</div>
      <div class="weekday">星期日</div>
    </div>
    <div class="cal">
      <div
        v-for="date of dates"
        :key="date.fulldate"
        @dblclick="handleDateDblClick"
        class="date"
        :class="date.class">
        <div v-if="date.class === 'enabled'" class="control">
          <i v-if="couldAddRemove(date.planStatExaminationGroup)" class="el-icon-circle-plus" @click="handleAdd(date)"></i>
          <i v-if="couldAddRemove(date.planStatExaminationGroup)" class="el-icon-remove" @click="handleDelete(date)"></i>
          <i class="iconfont iconfujian" style="display: none;"></i>
          <i v-if="couldTransfer(date.planStatExaminationGroup)" class="iconfont iconchuansong" @click="handleSubmitClick(date.planStatExaminationGroup)"></i>
          <i v-if="finalSubmitShow" @click="handleFinalSubmitClick(date.planStatExaminationGroup)" class="iconfont iconshenpi"></i>
          <i v-if="finalSubmitShow" class="iconfont iconweixin"></i>
        </div>
        <div class="text">
          {{ date.date }}
        </div>
        <div v-if="date.class === 'enabled'" class="approval-status">
          <el-tooltip
            :open-delay="500"
            placement="top"
            :content="date.formattedApprovalStatus">
            <div class="dot"></div>
          </el-tooltip>
        </div>
        <div v-if="date.class === 'enabled'" class="content">
          <div
            v-for="planStatExamination of date.planStatExaminations"
            :key="planStatExamination.planStatExaminationGuid"
            class="report">
            <el-tooltip
              :open-delay="500"
              placement="bottom"
              :content="planStatExamination.report.reportAlias + ' ' + planStatExamination.report.reportName">
              <div>
                {{ planStatExamination.report.reportAlias }}
                <div class="cover">
                  <img src="@/assets/images/svg/detail.svg" @click="handleClick(planStatExamination)" alt="">
                  <img src="@/assets/images/svg/report.svg" @click="handleReportClick(planStatExamination, 'pdf', true)" alt="">
                  <img src="@/assets/images/svg/excel.svg" @click="handleReportClick(planStatExamination, 'xlsx')" alt="">
                </div>
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>

    <my-dialog
      ref="submitFormDialog"
      title="传送"
      size="md"
      ok-text="确定"
      cancel-text="取消"
      @ok-click="doSubmit"
      >
      <el-form class="text-center">
        <el-form-item>
          <el-cascader
            v-if="identityAndUsers.length"
            ref="myCascader"
            :props="{ children: 'users' }"
            :options="identityAndUsers"
            clearable>
          </el-cascader>
        </el-form-item>
      </el-form>
    </my-dialog>

    <my-dialog
      ref="myDialog"
      :title="dialogTitle"
      :hide-ok="true"
      cancel-text="关闭"
      body-height="700px"
      size="xl">
      <iframe ref="myIFrame" :src="url" width="100%" height="100%" scrolling="no" frameborder="0"></iframe>
    </my-dialog>

    <my-dialog
      ref="importDialog"
      title="导入"
      cancel-text="取消"
      ok-text="确定"
      @ok-click="doImport"
      >
      <el-upload
        class="upload-control"
        ref="upload"
        :action="uploadAction"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :on-error="handleError"
        :limit="1"
        :accept="accept"
        :data="uploadData"
        :auto-upload="false">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <div slot="tip" class="el-upload__tip">只能上传{{ tip }}文件，且不超过10MB</div>
      </el-upload>
    </my-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import planStatExaminationGroupService from '@/services/planStatExaminationGroupService'
import PlanStatExaminationGroupModel from '@/model/PlanStatExaminationGroupModel'
import MyDialog from '@/components/MyDialog/MyDialog'
import { mapGetters, mapMutations } from 'vuex'
import utility from '@/common/utility'
import config from '@/config'
import auth from '@/common/auth'

export default {
  name: 'Cal',
  components: {
    MyDialog
  },
  data () {
    return {
      currentYear: new Date().getDate() <= 15 ? new Date().getFullYear() : parseInt(moment().add(1, 'months').format('YYYY')),
      currentMonth: new Date().getDate() <= 15 ? new Date().getMonth() : moment().add(1, 'months').month(),
      dates: [],
      descReports: [
        'GSM01',
        'GSM02',
        'GSM03',
        'GSM04',
        'GSM05',
        'GSM06'
      ],
      dialogTitle: '',
      url: '',
      planStatExaminationGroups: [],
      uploadData: {},
      uploadAction: `${config.reportHost}/UploadFile`,
      examinationModel: null,
      planStatExaminationGroup: null,
      attachmentUploadData: {},
      attachmentFileList: [],
      attachmentUploadAction: `${config.reportHost}/UploadFile`,
      attachmentList: [],
      accept: '.xls,.xlsx',
      tip: 'Excel',
      identityAndUsers: [],
      planStatExaminationModel: {}
    }
  },
  computed: {
    searchBarShow () {
      let identityGuid = auth.getUserInfo().identityGuid
      return config.applyIdentities.indexOf(identityGuid) !== -1
    },
    formattedMonth () {
      let endDate = moment([ this.currentYear, this.currentMonth, config.monthStart ])
      return endDate.format('YYYY年M月')
    },
    reportPrefix () {
      return config.reportHost + '/' + config.reportFolder
    },
    finalSubmitShow () {
      let identityGuid = auth.getUserInfo().identityGuid
      return config.finalSubmitIdentities.indexOf(identityGuid) !== -1
    },
    ...mapGetters('planStatExamination', {
      tenderGuid: 'getTenderGuid',
      examinationType: 'getExaminationType'
    })
  },
  methods: {
    handleFinalSubmitClick (row) {
      this.$confirm('确定要最终批复吗？')
        .then(() => {
          this.planStatExaminationGroupModel = row
          planStatExaminationGroupService.finalSubmit(this.planStatExaminationGroupModel.planStatExaminationGroupGuid)
            .then(res => {
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '最终批复成功！'
                })
                this.getData()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '最终批复失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消操作！'
          })
        })
    },
    doSubmit () {
      let nodes = this.$refs.myCascader.getCheckedNodes()
      if (!nodes.length) {
        this.$message({
          type: 'warning',
          message: '请选择传送对象！'
        })
        return
      }
      let userModel = nodes[0].data
      let flowModel = nodes[0].parent.data

      planStatExaminationGroupService.transfer(userModel.userGuid, flowModel.stepName, flowModel.flowGuid, this.planStatExaminationGroupModel.planStatExaminationGroupGuid, flowModel.sortId)
        .then(res => {
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '传送成功！'
            })
            this.getData()
            this.$refs.submitFormDialog.close()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '传送失败！'
          })
        })
    },
    handleSubmitClick (row) {
      this.planStatExaminationGroupModel = row
      this.getNextUsers(row.planStatExaminationGroupGuid)
    },
    getNextUsers (planStatExaminationGroupGuid) {
      planStatExaminationGroupService.getUsers(planStatExaminationGroupGuid)
        .then(res => {
          if (res.data.code === 1) {
            let data = res.data.data
            if (data.length) {
              data.forEach(flow => {
                flow.label = flow.stepName
                flow.value = flow.flowGuid
                if (flow.users && flow.users.length) {
                  flow.users.forEach(user => {
                    user.label = user.staffName
                    user.value = user.userGuid
                  })
                }
              })
              this.$refs.submitFormDialog.open()
              this.identityAndUsers = data
            }
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败！'
          })
        })
    },
    couldTransfer (examinationGroupModel) {
      if (examinationGroupModel) {
        return auth.getUserInfo().userGuid === examinationGroupModel.approvalUserGuid
      }
      return false
    },
    couldAddRemove (examinationGroupModel) {
      let identityGuid = auth.getUserInfo().identityGuid
      if (examinationGroupModel) {
        return auth.getUserInfo().userGuid === examinationGroupModel.approvalUserGuid && config.applyIdentities.indexOf(identityGuid) !== -1
      } else {
        return config.applyIdentities.indexOf(identityGuid) !== -1
      }
    },
    handleAdd (date) {
      if (date.planStatExaminationGroup && date.planStatExaminationGroup.planStatExaminations && date.planStatExaminationGroup.planStatExaminations.length) {
        this.$message({
          type: 'error',
          message: '数据已存在，不能重复创建！'
        })
        return
      }
      let planStatExaminationGroupModel = new PlanStatExaminationGroupModel({
        tenderGuid: auth.getUserInfo().tenderGuid,
        examinationType: 'dateStat',
        year: parseInt(date.year),
        month: parseInt(date.month),
        date: parseInt(date.date)
      })
      planStatExaminationGroupService.add(planStatExaminationGroupModel)
        .then(res => {
          if (res.data.code === 1) {
            this.getData()
            this.$message({
              type: 'success',
              message: '操作成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '操作失败！'
          })
        })
    },
    handleDelete (date) {
      if (date.planStatExaminationGroup && date.planStatExaminationGroup.planStatExaminations && date.planStatExaminationGroup.planStatExaminations.length) {
        this.$confirm('您确定要删除吗？')
          .then(() => {
            planStatExaminationGroupService.deleteDateStat(date.planStatExaminationGroup.planStatExaminationGroupGuid)
              .then(res => {
                if (res.data.code === 1) {
                  this.getData()
                  this.$message({
                    type: 'success',
                    message: '操作成功！'
                  })
                } else {
                  this.$message({
                    type: 'error',
                    message: res.data.msg
                  })
                }
              })
              .catch(err => {
                console.log(err)
                this.$message({
                  type: 'error',
                  message: '操作失败！'
                })
              })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '您已取消操作！'
            })
          })
      } else {
        this.$message({
          type: 'error',
          message: '要删除的数据不存在，请检查后再删除！'
        })
      }
    },
    handleClick (row) {
      this.setPlanStatExaminationGuid(row.planStatExaminationGuid)
      this.setTenderGuid(row.tenderGuid)
      this.setTenderName(row.tenderName)
      this.setYear(row.year)
      this.setMonth(row.month)
      this.setDate(row.date)
      this.setExaminationCode(row.examinationCode)
      this.setApprovalUserGuid(row.approvalUserGuid)

      if (this.descReports.indexOf(row.examinationType) !== -1) {
        this.handleImport(row)
      } else {
        this.$router.push(`/${row.examinationType}Index`)
      }
    },
    handleImport (examinationModel) {
      this.examinationModel = examinationModel
      if (this.descReports.indexOf(this.examinationModel.examinationType) !== -1) {
        this.accept = '.pdf'
        this.tip = 'PDF'
      } else {
        this.accept = '.xls,.xlsx'
        this.tip = 'EXCEL'
      }

      this.$refs.importDialog.open()
    },
    doImport () {
      let isAnalysis = 1
      if (this.descReports.indexOf(this.examinationModel.examinationType) !== -1) {
        isAnalysis = 0
      }
      let timestamp = utility.getCurrentTimestamp()
      let token = utility.getMd5(`${this.examinationModel.tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

      this.uploadAction = `${config.reportHost}/UploadFile?date=${utility.formatTime(new Date(this.examinationModel.year, this.examinationModel.month - 1, this.examinationModel.date), 8)}&isanalysi=${isAnalysis}&examination=${this.examinationModel.planStatExaminationGuid}&userguid=${this.examinationModel.createUserGuid}&timestamp=${timestamp}&parameter=${token}&Category=0`

      this.uploadData = {
        date: utility.formatTime(new Date(this.examinationModel.year, this.examinationModel.month - 1, this.examinationModel.date), 8),
        isanalysi: isAnalysis,
        examination: this.examinationModel.planStatExaminationGuid,
        userguid: this.examinationModel.createUserGuid,
        timestamp: timestamp,
        parameter: token
      }

      this.$nextTick(() => {
        this.$refs.upload.submit()
      })
    },
    handleRemove () {
    },
    handleSuccess () {
      this.$message({
        type: 'success',
        message: '导入成功！'
      })
      this.$refs.importDialog.close()
    },
    handleError () {
      this.$message({
        type: 'error',
        message: '导入失败！'
      })
      this.$refs.importDialog.close()
    },
    handleReportClick (examinationModel, format, inline = false) {
      if (this.descReports.indexOf(examinationModel.examinationType) !== -1) {
        let timestamp = utility.getCurrentTimestamp()
        let token = utility.getMd5(`${examinationModel.tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

        let tempList = []
        axios.get(`${config.reportHost}/GetFileList`, {
          params: {
            examination: examinationModel.planStatExaminationGuid,
            userguid: examinationModel.createUserGuid,
            timestamp: timestamp,
            parameter: token
          }
        }).then(res => {
          if (res.data.Code === 1) {
            tempList = res.data.Msg.sort((a, b) => b.SortId - a.SortId)
            if (tempList.length) {
              window.open(`${this.reportPrefix + tempList[0].SavePath}`, '_blank')
            }
          } else {
            this.$message({
              type: 'error',
              message: res.data.Msg
            })
          }
        }).catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '获取附件失败，请重试！'
          })
        })
        return
      }
      let date = `${examinationModel.year}${utility.padStart(examinationModel.month, 2)}${utility.padStart(examinationModel.date, 2)}`
      let timestamp = utility.getCurrentTimestamp()
      let token = utility.getMd5(`${this.tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

      this.url = `${config.reportHost}/reportjhtj?format=${format}&inline=${inline}&date=${date}&tender=${this.tenderGuid}&report=${examinationModel.report.reportGuid}&timestamp=${timestamp}&parameter=${token}`

      if (inline) {
        this.dialogTitle = examinationModel.report.reportAlias + ' ' + examinationModel.report.reportName
        this.$refs.myDialog.open()
      } else {
        window.open(this.url, '_blank')
      }
    },
    handleDateDblClick () {
      alert('不要双击哦，小顽皮！')
    },
    handlePrev () {
      --this.currentMonth
      if (this.currentMonth < 0) {
        this.currentMonth = 11
        --this.currentYear
      }
      this.init()
    },
    handleNext () {
      ++this.currentMonth
      if (this.currentMonth > 11) {
        this.currentMonth = 0
        ++this.currentYear
      }
      this.init()
    },
    init () {
      let endDate = moment([ this.currentYear, this.currentMonth, config.monthStart ])
      let startDate = endDate.clone().add(-1, 'months').set('date', config.monthStart)

      let tempDates = []
      let padStartLen = (startDate.weekday() === 0 ? 7 : startDate.weekday()) - 1
      for (let i = 1; i <= padStartLen; ++i) {
        let tempDate = startDate.clone().subtract(i, 'days')
        tempDates.unshift({
          fulldate: tempDate.format('YYYYMMDD'),
          year: tempDate.format('YYYY'),
          month: tempDate.format('M'),
          date: tempDate.format('D'),
          weekday: tempDate.weekday() === 0 ? 7 : tempDate.weekday(),
          class: 'disabled'
        })
      }

      for (let start = startDate.clone(); !start.isSame(endDate); start.add(1, 'days')) {
        tempDates.push({
          fulldate: start.format('YYYYMMDD'),
          year: start.format('YYYY'),
          month: start.format('M'),
          date: start.format('D'),
          weekday: start.weekday() === 0 ? 7 : start.weekday(),
          class: 'enabled'
        })
      }

      endDate.subtract(1, 'days')
      let padEndLen = 7 - (endDate.weekday() === 0 ? 7 : endDate.weekday())
      for (let i = 1; i < padEndLen + 1; ++i) {
        let tempDate = endDate.clone().add(i, 'days')
        tempDates.push({
          fulldate: tempDate.format('YYYYMMDD'),
          year: tempDate.format('YYYY'),
          month: tempDate.format('M'),
          date: tempDate.format('D'),
          weekday: tempDate.weekday() === 0 ? 7 : tempDate.weekday(),
          class: 'disabled'
        })
      }

      tempDates.forEach(item => {
        const group = this.planStatExaminationGroups.find(group => moment([ group.year, group.month - 1, group.date ]).format('YYYYMMDD') === item.fulldate)
        item.planStatExaminationGroup = group
        item.planStatExaminations = []
        item.formattedApprovalStatus = group ? group.approvalStatus + (group.approvalUser ? ' - ' + group.approvalUser.staffName : '') : '未提交'
        if (group && group.planStatExaminations && group.planStatExaminations.length) {
          item.planStatExaminations = group.planStatExaminations
        }
      })

      this.dates = tempDates
    },
    getData () {
      planStatExaminationGroupService.list({}, { examinationType: this.examinationType, tenderGuid: this.tenderGuid })
        .then(res => {
          this.planStatExaminationGroups = res.data.data
          this.planStatExaminationGroups.forEach(item => {
            item.planStatExaminations = item.planStatExaminations.sort((a, b) => a.report.reportAlias <= b.report.reportAlias ? -1 : 1)
          })
          this.$message({
            type: 'success',
            message: '加载列表成功！'
          })
          this.init()
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载失败'
          })
        })
    },
    ...mapMutations('planStatExamination', {
      setPlanStatExaminationGuid: 'setPlanStatExaminationGuid',
      setTenderGuid: 'setTenderGuid',
      setTenderName: 'setTenderName',
      setYear: 'setYear',
      setMonth: 'setMonth',
      setDate: 'setDate',
      setExaminationCode: 'setExaminationCode',
      setApprovalUserGuid: 'setApprovalUserGuid'
    })
  },
  mounted () {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.cal-index {
  width: 100%;
  height: 100%;
  .cal-control {
    line-height: 50px;
    position: relative;
    .text {
      position: absolute;
      left: 50%;
      top: 50%;
      font-weight: bolder;
      transform: translateY(-50%) translateX(-50%);
      user-select: none;
    }
  }
  .cal-header {
    height: 30px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    background-color: #eee;
    .weekday {
      line-height: 30px;
      height: 30px;
      min-width: 180px;
      border: 1px solid #ccc;
      text-align: center;
    }
  }
  .cal {
    width: 100%;
    height: calc(100% - 160px);
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    .date {
      min-width: 180px;
      min-height: 150px;
      border: 1px solid #ccc;
      position: relative;
      .control {
        position: absolute;
        display: none;
        left: 10px;
        top: 10px;
        i {
          margin-right: 5px;
        }
      }
      &:hover .control {
        display: block;
        i {
          cursor: pointer;
        }
      }
      .approval-status {
        position: absolute;
        top: 16px;
        right: 30px;
        .dot {
          cursor: pointer;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: red;
        }
      }
      .text {
        position: absolute;
        top: 10px;
        right: 10px;
        user-select: none;
      }
      .content {
        position: absolute;
        top: 39px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-items: center;
        gap: 10px 0;
        .report
        {
          color: #333;
          font-size: 15px;
          font-weight: normal;
          width: 90%;
          height: 45px;
          line-height: 45px;
          border-radius: 5px;
          text-align: center;
          cursor: pointer;
          position: relative;
          z-index: 998;
          background-color: rgb(23, 162, 184);
          .cover {
            position: absolute;
            display: none;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 999;
            background-color: rgba(#ccc, .8);
            border-radius: 5px;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            justify-items: center;
            img {
              width: 20px;
            }
          }
          &:hover .cover {
            display: grid;
          }
        }
      }
    }
    .disabled {
      color: #eee;
      background-color: #aaa;
    }
    .enabled {
      color: black;
      font-weight: bold;
    }
  }
}
</style>
